/* Quote styles */

.image-canvas {
  /* skeleton */
  @apply relative flex h-72 w-72 content-center items-center justify-center md:mr-16 lg:mr-0;

  /* bg image */
  @apply bg-cover bg-center bg-no-repeat;

  & > div {
    @apply w-[90%];
  }

  &.snippet-anakiwa-1 {
    @apply bg-snippet-anakiwa-1;
  }

  &.snippet-tacao-1 {
    @apply bg-snippet-tacao-1;
  }

  &.snippet-tawnyport-1 {
    @apply bg-snippet-tawnyport-1;
  }

  &.snippet-yellow-1 {
    @apply bg-snippet-yellow-1;
  }
}

:global(.dark) .image-canvas {
  &.snippet-anakiwa-1-dark {
    @apply bg-snippet-anakiwa-1;
  }

  &.snippet-tacao-1-dark {
    @apply bg-snippet-tacao-1;
  }

  &.snippet-tawnyport-1-dark {
    @apply bg-snippet-tawnyport-1;
  }

  &.snippet-yellow-1-dark {
    @apply bg-snippet-yellow-1;
  }
}

.quote-marks {
  /* skeleton */
  @apply h-20 w-20;

  /* image */
  @apply bg-contain bg-center bg-no-repeat;

  &.is-white {
    @apply bg-quote-marks-white;
  }

  &.is-black {
    @apply bg-quote-marks-dark;
  }
}

:global(.dark) .quote-marks {
  &.is-white-dark {
    @apply bg-quote-marks-white;
  }

  &.is-black-dark {
    @apply bg-quote-marks-dark;
  }
}

.quote-cite-name {
  @apply font-bold uppercase;
}

.quote-text {
  @apply m-0 max-w-prose px-0 pt-2 pb-4;

  @apply font-headlines text-2xl font-semibold leading-relaxed tracking-wide lg:text-3xl;

  &.is-white {
    @apply text-white;
  }

  &.is-black {
    @apply text-black;
  }
}

:global(.dark) .quote-text {
  &.is-white-dark {
    @apply text-white;
  }

  &.is-black-dark {
    @apply text-black;
  }
}

.quote-cite-name {
  @apply font-bold uppercase;
}

.quote-cite-description {
}

.quote-cite {
  /* skeleton */
  @apply mt-2 mb-4 flex flex-col;

  /* font */
  @apply font-default tracking-wide;

  &.is-white {
    @apply text-gray-four;
  }

  &.is-black {
    @apply text-gray-two;
  }
}

:global(.dark) .quote-cite {
  &.is-white-dark {
    @apply text-gray-four;
  }

  &.is-black-dark {
    @apply text-gray-two;
  }
}

.quote-container {
  @apply flex h-full w-full flex-col;
}
